/* eslint-disable */
import { useReducer, createContext } from 'react';
import userReducer from './userReducer';

export const UserContext = createContext({});

const UserContextProvider: React.FC = ({ children }) => {
    const [user, dispatch] = useReducer(userReducer, null);

    return (
        <UserContext.Provider value={{ user, dispatch }}>
            {children}
        </UserContext.Provider>
    );
};

export default UserContextProvider;
