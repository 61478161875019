import { useEffect, useContext } from 'react';
import { isAuthenticated } from '../utils/helpers';
import { UserContext } from '../context/user/userContextProvider';
import User from '../models/user';

export default function CustomerChat() {
    const { user } = useContext<any>(UserContext);

    const authSmartUpp = (customer: User) => {
        window.smartsupp('name', `${customer?.firstName} ${customer?.lastName}`);
        window.smartsupp('email', customer.email || '');
        window.smartsupp('User_ID', customer.id || '');
    };

    useEffect(() => {
        const chat = document.getElementById('chat-application');

        if (chat) {
            chat.style.display = 'block';
        }

        if (isAuthenticated(user)) {
            authSmartUpp(user);
        }

        return () => {
            if (chat) {
                chat.style.display = 'none';
            }
        };
    }, []);

    return null;
}
